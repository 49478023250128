import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';

import { getAllItem } from "../../redux/actions/item.action"
import { getRights } from "../../redux/actions/userRights.action";
import { ReadCookie } from "../../utils/readCookie"

import Tables from '../../components/Table/Tables';
import SkeletonLoad from '../../components/Skeleton/SkeletonLoad';
import SubTitle from "../../components/SubTitle/SubTitle";
import Loader from "react-loader-spinner";
import PageHeader from "../../components/PageHeader/PageHeader";

import { Button, Tabs, Modal, Input, Form, Row, Col, Popconfirm, } from 'antd';
import { EditOutlined, DeleteOutlined, FilterFilled, SearchOutlined, CloseCircleOutlined } from "@ant-design/icons"

import "../Style/style.css";
import "./product.css";
import { TabTitle } from '../../utils/GeneralFunction';
import { CSVLink } from "react-csv";


export default function ProductList({ token }) {
  TabTitle("Product Information")
  const history = useHistory();
  const menuId = ReadCookie('menuId');

  const dispatch = useDispatch();
  const [show, setShow] = useState(true);
  const { item, userRights } = useSelector(state => state);

  const [itemId, setItemId] = useState(null);
  const [filteredInfo, setFilteredInfo] = useState(null);
  const [sortedInfo, setSortedInfo] = useState(null);
  const [dataLen, setDataLen] = useState(0);
  const [excelData, setExcelData] = useState([]);

  useEffect(() => {
    dispatch(getRights(token, menuId));
    dispatch(getAllItem(token, "Y")).then((res) => {
      if(res?.payload?.data.length > 0) {
        setExcelData(res?.payload?.data);
      }
    })
  }, []);

  const colTest = [
    {
      title: "SKU", dataIndex: "sku", key: "sku", width: 130,
      filteredValue: filteredInfo ? filteredInfo.sku || null : null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <Input
            allowClear
            autoFocus
            className="search-pop-style"
            // placeholder='Search'
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false })
            }}
            onPressEnter={() => { confirm() }}
            onBlur={() => { confirm() }}
          />
        )
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.sku) return record.sku.toLowerCase().includes(value.toLowerCase())
      },
      sortOrder: sortedInfo ? sortedInfo.columnKey === 'sku' && sortedInfo.order : null,
      sorter: (a, b) => a.sku - b.sku,
    },
    {
      title: "Style/Fit", dataIndex: "style_fit", key: "style_fit", width: 130,
      filteredValue: filteredInfo ? filteredInfo.style_fit || null : null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <Input
            allowClear
            autoFocus
            className="search-pop-style"
            // placeholder='Search'
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false })
            }}
            onPressEnter={() => { confirm() }}
            onBlur={() => { confirm() }}
          />
        )
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.style_fit) return record.style_fit.toLowerCase().includes(value.toLowerCase())
      },
      sortOrder: sortedInfo ? sortedInfo.columnKey === 'style_fit' && sortedInfo.order : null,
      sorter: (a, b) => a.style_fit.localeCompare(b.style_fit),
    },
    {
      title: "Cut", dataIndex: "cut", key: "cut", width: 130,
      filteredValue: filteredInfo ? filteredInfo.cut || null : null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <Input
            allowClear
            autoFocus
            className="search-pop-style"
            // placeholder='Search'
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false })
            }}
            onPressEnter={() => { confirm() }}
            onBlur={() => { confirm() }}
          />
        )
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.cut) return record.cut.toLowerCase().includes(value.toLowerCase())
      },
      sortOrder: sortedInfo ? sortedInfo.columnKey === 'cut' && sortedInfo.order : null,
      sorter: (a, b) => a.cut.localeCompare(b.cut),
    },
    {
      title: "Wash", dataIndex: "wash", key: "wash", width: 130,
      filteredValue: filteredInfo ? filteredInfo.wash || null : null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <Input
            allowClear
            autoFocus
            className="search-pop-style"
            // placeholder='Search'
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false })
            }}
            onPressEnter={() => { confirm() }}
            onBlur={() => { confirm() }}
          />
        )
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.wash) return record.wash.toLowerCase().includes(value.toLowerCase())
      },
      sortOrder: sortedInfo ? sortedInfo.columnKey === 'wash' && sortedInfo.order : null,
      sorter: (a, b) => a.wash.localeCompare(b.wash),

    },
    {
      title: "Wash Type", dataIndex: "wash_type", key: "wash_type", width: 130,
      filteredValue: filteredInfo ? filteredInfo.wash_type || null : null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <Input
            allowClear
            autoFocus
            className="search-pop-style"
            // placeholder='Search'
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false })
            }}
            onPressEnter={() => { confirm() }}
            onBlur={() => { confirm() }}
          />
        )
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.wash_type) return record.wash_type.toLowerCase().includes(value.toLowerCase())
      },
      sortOrder: sortedInfo ? sortedInfo.columnKey === 'wash_type' && sortedInfo.order : null,
      sorter: (a, b) => a.wash_type.localeCompare(b.wash_type),

    },
    {
      title: "Fabric", dataIndex: "fabric", key: "fabric", width: 130,
      filteredValue: filteredInfo ? filteredInfo.fabric || null : null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <Input
            allowClear
            autoFocus
            className="search-pop-style"
            // placeholder='Search'
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false })
            }}
            onPressEnter={() => { confirm() }}
            onBlur={() => { confirm() }}
          />
        )
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.fabric) return record.fabric.toLowerCase().includes(value.toLowerCase())
      },
      sortOrder: sortedInfo ? sortedInfo.columnKey === 'fabric' && sortedInfo.order : null,
      sorter: (a, b) => a.fabric.localeCompare(b.fabric),
    },
    {
      title: "Gender", dataIndex: "gender", key: "gender", width: 130,
      filteredValue: filteredInfo ? filteredInfo.gender || null : null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <Input
            allowClear
            autoFocus
            className="search-pop-style"
            // placeholder='Search'
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false })
            }}
            onPressEnter={() => { confirm() }}
            onBlur={() => { confirm() }}
          />
        )
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.gender) return record.gender.toLowerCase().includes(value.toLowerCase())
      },

      sortOrder: sortedInfo ? sortedInfo.columnKey === 'gender' && sortedInfo.order : null,
      sorter: (a, b) => a.gender.localeCompare(b.gender),

    },
    {
      title: "Gender Category", dataIndex: "gender_category_name", key: "gender_category_name", width: 130,
      filteredValue: filteredInfo ? filteredInfo.gender_category_name || null : null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <Input
            allowClear
            autoFocus
            className="search-pop-style"
            
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false })
            }}
            onPressEnter={() => { confirm() }}
            onBlur={() => { confirm() }}
          />
        )
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.gender_category_name) return record.gender_category_name.toLowerCase().includes(value.toLowerCase())
      },

      sortOrder: sortedInfo ? sortedInfo.columnKey === 'gender_category_name' && sortedInfo.order : null,
      sorter: (a, b) => a.gender_category_name.localeCompare(b.gender_category_name),

    },
    {
      title: "Product Status", dataIndex: "product_status", key: "product_status", width: 130,
      filteredValue: filteredInfo ? filteredInfo.product_status || null : null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <Input
            allowClear
            autoFocus
            className="search-pop-style"
            // placeholder='Search'
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false })
            }}
            onPressEnter={() => { confirm() }}
            onBlur={() => { confirm() }}
          />
        )
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.product_status) return record.product_status.toLowerCase().includes(value.toLowerCase())
      },
      sortOrder: sortedInfo ? sortedInfo.columnKey === 'product_status' && sortedInfo.order : null,
      sorter: (a, b) => a.product_status.localeCompare(b.product_status),
    },
    {
      title: "Active", dataIndex: "active", key: "active", width: 130,
      render: (text, row) => row.active ? "True" : "False",
      filteredValue: filteredInfo ? filteredInfo.active || null : null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <Input
            allowClear
            autoFocus
            className="search-pop-style"
            // placeholder='Search'
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false })
            }}
            onPressEnter={() => { confirm() }}
            onBlur={() => { confirm() }}
          />
        )
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,

      onFilter: (value, record) => {
        if (record.active !== undefined) return record.active.toString().toLowerCase().includes(value.toLowerCase())
      },
      sortOrder: sortedInfo ? sortedInfo.columnKey === 'active' && sortedInfo.order : null,
      sorter: (a, b) => a.active.localeCompare(b.active),


    },
    {
      title: "Retail Price", dataIndex: "retail_price", key: "retail_price", width: 130,
      filteredValue: filteredInfo ? filteredInfo.retail_price || null : null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <Input
            allowClear
            autoFocus
            className="search-pop-style"
            // placeholder='Search'
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false })
            }}
            onPressEnter={() => { confirm() }}
            onBlur={() => { confirm() }}
          />
        )
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.retail_price) return record.retail_price.toLowerCase().includes(value.toLowerCase())
      },
      sortOrder: sortedInfo ? sortedInfo.columnKey === 'retail_price' && sortedInfo.order : null,
      sorter: (a, b) => a.retail_price - b.retail_price,
    },
    {
      title: "Cost Price", dataIndex: "cost_price", key: "cost_price", width: 130,
      filteredValue: filteredInfo ? filteredInfo.cost_price || null : null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <Input
            allowClear
            autoFocus
            className="search-pop-style"
            // placeholder='Search'
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false })
            }}
            onPressEnter={() => { confirm() }}
            onBlur={() => { confirm() }}
          />
        )
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.cost_price) return record.cost_price.toLowerCase().includes(value.toLowerCase())
      },
      sortOrder: sortedInfo ? sortedInfo.columnKey === 'cost_price' && sortedInfo.order : null,
      sorter: (a, b) => a.cost_price - b.cost_price,
    },
    {
      title: "Item Id", dataIndex: "item_id", key: "item_id", width: 130,
      filteredValue: filteredInfo ? filteredInfo.item_id || null : null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <Input
            allowClear
            autoFocus
            className="search-pop-style"
            // placeholder='Search'
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false })
            }}
            onPressEnter={() => { confirm() }}
            onBlur={() => { confirm() }}
          />
        )
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.item_id) return record.item_id.toString().toLowerCase().includes(value.toLowerCase())
      },
      sortOrder: sortedInfo ? sortedInfo.columnKey === 'item_id' && sortedInfo.order : null,
      sorter: (a, b) => a.item_id - b.item_id,
    },
  ]
  const handleFilter = (pagination, filters, sorter, extra) => {
    setFilteredInfo(filters)
    setSortedInfo(sorter)
    setShow(true)
  }
  const clearFilters = () => {
    if (show) {
      setFilteredInfo(null)
      setSortedInfo(null)
      setShow(false)
    }
    if (!show) {
      setShow(!show)
    }
  };


  return (
    <>
      <div className='styleContainer' >
        <div className='styleBody h-100'>
          <div className='mainProductContainer new-style'>
            <PageHeader userRights={userRights?.payload} title={"Product Information"} type={"table"} />
            <div className='productListDiv' >
              <SubTitle name={"Product List"} />
              {userRights?.payload?.can_add &&
                <Button className="pos-right-10 iconBtn crudBTN" shape='circle' icon={<CloseCircleOutlined className='color-red' />} onClick={() => history.push({ pathname: "/product-info" })} />
              }
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end", padding: 5 }}>
              <CSVLink
                style={{ border: "1px solid black", padding: 3 }}
                filename={"product-list.csv"}
                data={excelData}
              >
                Download Excel
              </CSVLink>
            </div>
            {
              item.loading ?
                <div className='h-100 relative'>
                  <div className='loaderDiv' style={{ height: "80vh" }}>
                    <Loader
                      type="Grid"
                      color="#212121"
                      height={40}
                      width={40}
                    />
                  </div>
                  <SkeletonLoad />
                </div>
                :
                <div className='productTable pt-0'>
                  <Tables dataLen={dataLen} setDataLen={setDataLen} handleFilter={handleFilter} loadHeight={40} loadWidth={40} itemId={setItemId} col={colTest} load={item?.loading} scroll={570} perPage={25} source={item?.payload?.data} paginate scrollX={"80rem"} type={"item"} />
                </div>
            }
          </div>
        </div>
      </div>
    </>
  )
}