import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Row as Brow, Col as Bcol } from "reactstrap";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import { TabTitle } from "../../utils/GeneralFunction";
import { getSaleOrderFormData } from "../../redux/actions/salesOrder.action";
import { Button, Form, Input, Select, DatePicker, Table, Spin } from "antd";
import moment from "moment";
import { ReadCookie } from "../../utils/readCookie";
import { getPendingSummary } from "../../redux/actions/pendingSummary.action";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { SearchOutlined, FilterFilled } from '@ant-design/icons';
import numeral from "numeral";
import { loadCustomers } from "../../redux/actions/customerForm";
import Loader from "react-spinners/BarLoader";
import { getARAccounts } from "../../redux/actions/AR_Account.action";
import { getCustomerRefundNewReport, getCustomerRefundReport } from "../../redux/actions/customerRefundReport.action";
import { CSVLink } from "react-csv";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
const { Option } = Select;

const CustomerRefundReportNew = () => {
  TabTitle("customer-refund-report")
  const [form] = Form.useForm();
  const [minimize, setMinimize] = useState(false);
  const [toogle, setToogle] = useState(false);
  const submitButton = useRef(null);
  const dispatch = useDispatch();
  const menuId = ReadCookie("menuId");
  const token = ReadCookie("token");
  const [pendingSummaryList, setPendingSummaryList] = useState([]);
  const [customer, setCustomer] = useState([])
  const [loading, setLoading] = useState(false);
  const [excelData, setExcelData] = useState([])
  const tableRef = useRef(null);
  const queryParams = new URLSearchParams(window.location.search);
  const customerID = queryParams.get("customer_id");
  const companyId = ReadCookie("defaultCompany");
  const {
    getPendingSummaryReducer,
    customerForms,
    arAccount,
    customerRefundNew
  } = useSelector((state) => state);



  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 50,
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Refund #",
      dataIndex: "refund_header_no",
      key: "refund_header_no",
      // width: 100,
      render: (text, record) => (
        <Link
        to={"/customer-refund/edit/" + record.refund_header_id + "?edit=yes"}
        >
          {text}
        </Link>),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.refund_header_no.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.refund_header_no - b.refund_header_no;
      },
    },
    {
      title: "Refund Date",
      render: (text, record) => {
        return <>{moment(record.refund_date).format("MM-DD-YY")}</>
      },
      // width: 120,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.refund_date.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.refund_date - b.refund_date;
      },

    },
    {
      title: "Customer",
      dataIndex: "customer_name",
      key: "customer_name",
      // width: 200,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.customer_name.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.customer_name - b.customer_name;
      },
    },
    {
      title: "Doc #",
      dataIndex: "note_header_no",
      key: "note_header_no",
      // width: 200,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.note_header_no.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.note_header_no - b.note_header_no;
      },
    },
    {
      title: "Transaction Type",
      dataIndex: "transaction_type",
      key: "transaction_type",
      // width: 200,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.transaction_type.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.transaction_type - b.transaction_type;
      },
    },
    {
      title: "Gender",
      dataIndex: "gender_name",
      key: "gender_name",
      // width: 200,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.gender_name.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.gender_name - b.gender_name;
      },
    },
    {
      title: "Invoice #",
      dataIndex: "invoice_header_no",
      key: "invoice_header_no",
      // width: 200,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.invoice_header_no.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.invoice_header_no - b.invoice_header_no;
      },
    },
    {
      title: "RA #",
      dataIndex: "ra_no",
      key: "ra_no",
      // width: 200,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.ra_no.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.ra_no - b.ra_no;
      },
    },
    {
      title: 'Amount',
      dataIndex: 'applied_amount',
      key: 'applied_amount',
      // width: 120,
      render: (text, record) => {
        return <>$ {parseFloat(record.applied_amount).toFixed(2)}</>;
      },
      align: "right",
    },
    {
      title: "Term Name",
      dataIndex: "term_name",
      key: "term_name",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.term_name.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.term_name - b.term_name;
      },
    },

    {
      title: "Bank",
      dataIndex: "debit_account_name",
      key: "debit_account_name",
      // width: 120,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.debit_account_name.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.debit_account_name - b.debit_account_name;
      },
    },

    {
      title: "Division",
      dataIndex: "credit_account_name",
      key: "credit_account_name",
      // width: 120,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.credit_account_name.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.credit_account_name - b.credit_account_name;
      },
    },

  ];

  const handleCustomerChanges = (val) => {
    if (val === "" || val === 'All') return
    setCustomer(val);
    dispatch(loadCustomers(token, null, null, null, null, null, val, null, null, null, null, null, null, null, null, null, null, null,)).then(
      (res) => {
        if (res?.type === "GET_ALL_CUSTOMERS_SUCCESS") {
          setCustomer(res?.payload?.data)
        }
      }
    );
  };

  function debounce(cb, delay = 1000) {
    let timeout
    return (...args) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        cb(...args)
      }, delay)
    }
  }

  const optimizedFn = useCallback(debounce(handleCustomerChanges), []);

  const onFinish = async () => {
    setLoading(true);
    await form.validateFields().then((values) => {
      dispatch(getCustomerRefundReport(token, values)).then((res) => {
        setToogle(true);
        setLoading(false);
        setPendingSummaryList(res?.payload?.data);
      });
    }).catch((error) => {
      setLoading(false);
    })
  }


  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  let invoice_id = query.get("invoice_id");

  console.log({invoice_id})

  const values = {
    invoice_id: invoice_id,
  };

  useEffect(() => {
    if (invoice_id) {
    setLoading(true);
    dispatch(getCustomerRefundNewReport(token, values)).then((res) => {
      setLoading(false);
      setPendingSummaryList(res?.payload?.data);
      downloadExcelFileData(res?.payload?.data)
    });
  }
  }, [invoice_id]);
  

  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "Refund #": v.refund_header_no,
          "Payment Date": moment(v.refund_date).format("MM-DD-YY"),
          "Customer": v.customer_name,
          "Doc #": v.note_header_no,
          "Transaction Type": v.transaction_type,
          "Gender": v.gender_name,
          "Invoice #": v.invoice_header_no,
          "RA #": v.ra_no,
          "Amount": "$ "+v.applied_amount,
          "Term Name": v.term_name,
          "Bank": v.debit_account_name,
          "Division": v.credit_account_name,
        };
      })
    )
  }

  return (
    <div className="styleContainer">
      <div className="styleBody h-100">
        <PageHeader
          title={"Customer Refund Report"}
          minimize={setMinimize}
          check={minimize}
        />
        {!minimize && (
          <div className="bodyStyle">
            {/* <div className="formProduct p-0">
              <Form onFinish={onFinish} form={form} name="control-hooks">
                <div className="container-fluid pbpx-5 ptpx-5">
                  <Brow>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="">
                        <div className="relative">
                          <Form.Item
                            name="from_date"
                            label="From Date:"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            initialValue={moment().startOf('year')}
                          >
                            <DatePicker
                              size='small'
                              className="styleInput w-100"
                              format={"MM-DD-YYYY"}
                              defaultValue={moment().format("MM-DD-YYYY")}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div >
                        <div className="relative">
                          <Form.Item
                            name="to_date"
                            label="To Date:"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            initialValue={moment().endOf('year')}
                          >
                            <DatePicker
                              size='small'
                              className="styleInput w-100"
                              format={"MM-DD-YYYY"}
                              defaultValue={moment().format("MM-DD-YYYY")}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="firstSectionRo">
                        <Form.Item
                          labelAlign="left"
                          name="customerID"
                          label="Customer"
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 16 }}
                          rules={[{ required: true, message: "Customer is required" }]}
                        >
                          <Select
                            notFoundContent={customerForms?.loading ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <Loader type="Oval" color="#3c4b58" height={20} width={20} />
                            </div> : <div style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>No Data</div>}
                            size="small"
                            tabIndex={18}
                            autoComplete={"nope"}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            className="filter-fields"
                            loading={customerForms?.loading}
                            onSearch={(value) => {
                              optimizedFn(value)
                            }}
                            value={customer}
                          >
                            {customerForms?.payload?.data?.map(
                              (item) => {
                                return (
                                  <Option
                                    key={item.customer_id}
                                    value={item.customer_id}
                                  >
                                    {`${item.customer_code} ${item.customer_name}`}
                                  </Option>
                                );
                              }
                            )}
                          </Select>
                        </Form.Item>

                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="firstSectionRo">
                        <Form.Item label="Account" name="account_no" labelAlign="left" labelCol={{ span: 8 }}
                          wrapperCol={{ span: 16 }} initialValue={"All"}>
                          <Select
                            size="small"
                            autoComplete={"nope"}
                            allowClear
                            tabIndex={18}
                            showSearch
                            optionFilterProp="children"
                            className="filter-fields"
                            loading={arAccount.loading}
                          >
                            <Option value={null}>All</Option>
                            {arAccount?.payload?.data.map((item) => {
                              return (
                                <Option key={item.account_id} value={item.account_id}>
                                  {item.account_name}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="">
                        <div >
                          <Form.Item
                            name="transaction_no"
                            label="Transaction #"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          >
                            <Input
                              size='small'
                              className="styleInput"
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                  </Brow>
                </div>
              </Form>
              <Button
                htmlType="submit"
                type="primary"
                className="submit-btn-btm "
                onClick={onFinish}
              >
                Submit
              </Button>
            </div> */}
          </div>
        )}
      </div>
      <div style={{ marginTop: 10 }}>
        {
          loading ? (
            <div className=" report-wrapper-main d-flex align-items-center justify-content-center spin-style mt-3">
              <Spin size='large' />
            </div>
          ) : (
            pendingSummaryList.length > 0 ? (
              <Table
                title={() =>
                  <CSVLink
                      filename={"Customer-Refund.csv"}
                      data={excelData}
                      onClick={() => {
                      }}
                    >
                      Download Excel
                    </CSVLink>
                  }
                ref={tableRef}
                size="small"
                bordered={false}
                type="company"
                perPage={15}
                scroll={{
                  x: 'max-content',
                  // y: 450,
                }}
                
                dataSource={pendingSummaryList}
                columns={columns}
                loading={customerRefundNew?.loading}
                pagination={{ pageSize: 3000 }}
                summary={(pageData) => {
                  let amount_sum = pageData.reduce(function (
                    previousValue,
                    currentValue
                  ) {
                    return (previousValue += parseFloat(
                      currentValue.applied_amount
                    ));
                  },
                    0);

                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                           $ {amount_sum.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>

                        <Table.Summary.Cell></Table.Summary.Cell>

                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            ) : <div
              style={{
                backgroundColor: "#CCCCCC",
                borderWidth: "1pt",
                borderStyle: "Dashed",
                fontWeight: "bold",
                height: "25px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              No record(s)
            </div>
          )

        }
      </div>

    </div>
  )
}

export default CustomerRefundReportNew;
