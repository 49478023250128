import React, { useEffect } from "react";
import "../SalesReport.css";
import Logo from "../../../assets/images/logo-black.svg";
import { getSalesReportOrderPrint } from "../../../redux/actions/salesReport.action";
import { useSelector } from "react-redux";
import { ReadCookie } from "../../../utils/readCookie";
import { useDispatch } from "react-redux";
import moment from "moment";
import numeral from "numeral";

const dateFormatList = ["MM-DD-YYYY"];
const CreditMemoReportHeader = () => {
  const token = ReadCookie("token");
  const menuId = ReadCookie("menuId");
  const dispatch = useDispatch()
  const { salesReportOrderPrint, creditMemoReportPrint } = useSelector((state) => state);




  const headerData = creditMemoReportPrint && creditMemoReportPrint?.payload?.data[0];
  // useEffect(() => {
  //   dispatch(getSalesReportOrderPrint(token, values));
  // }, [dispatch, menuId, token]);

  let sumAmount = creditMemoReportPrint?.payload?.data.reduce(function (
    previousValue,
    currentValue
) {
    return (previousValue += parseFloat(
        currentValue.amount
    ));
},
    0);
    
  return (
    <div className='sales-report'>

      <div className="credit-report-header">
        {/* header Left */}
        <div className="header-left-side ">
          {/* Logo & Text */}
          <div className="sales-report-Logo-Container">
            <div>
              <img width={250} height={72} src={Logo} />
            </div>
            {/* Logo Items */}
            <div className="sales-report-Logo_items">
              <div>Warp Weft World Inc.</div>
              <div>121 Varick Street,4th Floor</div>
              <div>NEW YORK ,NY 10013, USA</div>
              <div>TEL : (646) 514-9736</div>
              <div>FAX : (212) 991-9989</div>
              <div>DUNS # : 08-005-3628</div>
            </div>
            {/* Logo Items */}
          </div>
          {/* Items details */}
          <div className="items-detail-container">
            {/* Sold To */}
            <div className="sold-to-Container">
              <div className="">
                <div className="font_weight">Customer</div>
                <br/>
                {/* <div className="sold-to-data">{headerData && headerData.customer_name}</div> */}
              </div>
              <div 
              // className="sold-to"
              >
                {/* <div className="font_weight"></div> */}
                <div className="sold-to-data">{headerData && headerData.customer_name}</div>
                <div className="sold-to-data">{headerData && headerData.address_1}</div>
                <div className="sold-to-data">{headerData && headerData.address_2}</div>
                <div className="sold-to-data">{headerData && headerData.city_name + " " + headerData.state_name + " "+ headerData.zip_code}</div>
                <div className="sold-to-data">{headerData && headerData.country_name}</div>
              </div>
            </div>
            {/* Sold To */}
            {/* Ship To */}
            {/* <div className="ship-to-Container">
          <div className="sold-to">
                <div className="font_weight">Ship To :</div>
                <div className="sold-to-data">{headerData && headerData.ship_to_addressee}</div>
              </div>
              <div className="sold-to">
                <div className="font_weight">Name :</div>
                <div className="sold-to-data">Attn: {headerData && headerData.customer_name}</div>
              </div>
              <div className="sold-to">
                <div className="font_weight">Address 1 :</div>
                <div className="sold-to-data">{headerData && headerData.ship_to_address_1}</div>
              </div>
              <div className="sold-to">
                <div className="font_weight">Address 2 :</div>
                <div className="sold-to-data">{headerData && headerData.ship_to_address_2}</div>
              </div>
              <div className="sold-to">
                <div className="font_weight">City :</div>
                <div className="sold-to-data">{headerData && headerData.ship_to_city_name}</div>
                <div className="font_weight">State :</div>
                <div className="sold-to-data">{headerData && headerData.ship_to_state_name}</div>
              </div>
              <div className="sold-to">
                <div className="font_weight">Country :</div>
                <div className="sold-to-data">{headerData && headerData.ship_to_country_name}</div>
                <div className="font_weight">ZIP :</div>
                <div className="sold-to-data">{headerData && headerData.ship_to_zip}</div>
              </div>
              <div className="sold-to">
                <div className="font_weight">Dept :</div>
                <div className="sold-to-data">{headerData && headerData.department}</div>
                <div className="font_weight">DC #</div>
                <div className="sold-to-data">{headerData && headerData.dc_no}</div>
                <div className="font_weight">Store :</div>
                <div className="sold-to-data">{headerData && headerData.store_name}</div>
              </div>

          </div> */}
            {/* Ship To */}
          </div>
          {/* Items details */}
        </div>
        {/* header Left */}
        {/* header Right */}
        
        <div className="header-right-side2" style={{height: "80px"}}>
          <div className="header-right-side3">
          <div className="sales-order-heading">Credit Memo#{headerData && headerData.note_header_no}</div>
          <div className="sales-order-heading">RA#{headerData && headerData.ra_no}</div>
          <div className="sales-order-items-container">
            <div className="sales-order-items-left">
            

              {/* <div className="font_weight">CN No :</div>
              <div className="font_weight">Date :</div> */}
              {/* <div className="font_weight">Total :</div> */}
            <div className="sales_order_content">
              {/* <div>{headerData && headerData.note_header_no}</div> */}
              <br/>
              <div>Date:{headerData && moment(headerData.note_date).format("MM/DD/YYYY")}</div>
              {/* <div>${sumAmount}</div> */}
            </div>
            </div>
          </div>
          </div>

          <div className="header-right-total" style={{marginTop: "10px"}}>
          <div className="sales-order-items-container2">
            <div className="sales-order-items-left">
              <div className="sales-order-heading">TOTAL</div>
            </div>
            <div className="sales_order_content2">
              <div>${numeral(sumAmount - headerData.re_stock).format("00.00")}</div>
            </div>
          </div>
          </div>

        </div>
        {/* header Right */}
      </div>
    </div>
  );
};

export default CreditMemoReportHeader;
